<template>
    <div>
        <div class="highlight">Alle relevanten Teilbereiche werden optimal koordiniert</div>
        <div class="box">            
            <h1>Verwalten / Koordinieren</h1>
            <p>
                Grundsätzlich werden über die Verwaltungsmodule die Stammdaten erfasst und verwaltet.
                Für den Patienten wird das gebuchte Zeitfenster entsprechend der Leistung mit einem Therapeuten oder einer Anwendung und einem Behandlungsraum vervollständigt.<br>Das Behandlungsangebot wird individuell, gemäß der Preisliste, hinterlegt.<br><br>
                In den Teilbereichen der Verwaltung werden Ressourcenengpässe sichtbar und können einfach, per drag and drop, bereinigt werden.
            </p>
        </div>
        <div class="box transparent">
            <center><h2 style="color: white; font-size: 3rem; text-shadow: 0 0 .5em grey;">Die Teilbereiche</h2></center>
            <kleine-boxen class="tabs">
                <button @click="tab = 'pv'" :class="{active: tab == 'pv'}">
                    Patientenverwaltung</button>
                <button @click="tab = 'mv'" :class="{active: tab == 'mv'}">
                    Mitarbeiterverwaltung</button>
                <button @click="tab = 'rv'" :class="{active: tab == 'rv'}">
                    Raumverwaltung</button>           
            </kleine-boxen>
        </div>
        <template v-if="tab == 'pv'">
            <div class="box">
                <h2>Patientenverwaltung</h2>
                <p>                    
                    Die Patientenverwaltung ermöglicht dem Orga-Team den Patienten einen Überblick zu geben und in der Zukunft 
                    liegende Termine zu organisieren. Zur Terminorganisation gehört sowohl die Zuweisung eines Mitarbeiters/Therapeuten 
                    als auch die Buchung eines Behandlungsraumes.<br><br>
                    <img src="../assets/patientendetail_monitor.png" alt=""/><br><br>
                </p>
            </div>
            <div style="height: 5em"></div>
        </template>
        <template v-else-if="tab == 'mv'"> 
            <div class="box">
                <h2>Mitarbeiterverwaltung</h2>
                <p>
                    Tagesüberblick über alle gebuchten Zeiten je Mitarbeiter. Änderungen werden per drag and drop vorgenommen.<br>
                    Es können sowohl die Zeiten als auch der Mitarbeiter angepasst werden.
                    <br><br><br>
                    <img src="../assets/mitarbeiter_monitor.png" alt=""><br><br><br>
                </p>
            </div>
            <div style="height: 5em"></div>
        </template>
        <template v-else-if="tab == 'rv'"> 
            <div class="box">
                <h2>Raumverwaltung</h2>
                <p>
                    damit schnell eine Übersicht über alle Räume eingesehen werden kann, gibt es den Zeitstrahl für die Raumverwaltung.
                </p><br>                
                <h3>Die Raum-Detail-Ansicht gibt zusätzliche Informationen zu den Räumen.</h3>
                <p>
                    Hier können nicht nur Hinweise für Mitarbeiter hinterlegt werden, sondern auch Zusatzinformationen wie z.B. eine Wegbeschreibung oder auch ein spezielles Hygienekonzept. 
                    Zusätzlich ist es möglich an dieser Stelle den Status für den jeweiligen Raum festzulegen. Ein Raum der wegen Umbaumaßnahmen gesperrt 
                    ist wird entweder auf inaktiv oder als reservierter Termin gesetzt.<br><br><br><img src="../assets/raumzeiten_monitor.png" alt="">
                </p><br><br>
            </div>
            <div style="height: 5em"></div>
        </template>
        <div class="box schlüsselloch">
            <wegweiser :schild-hoehe="15" v-on-scroll.repeat="() => wegweiserSichtbar = !wegweiserSichtbar" :visible="wegweiserSichtbar">
                <template #pfosten><div class="pfosten"></div></template>
                <div>Patient / Kunde</div>
                <div class="left" style="transform: rotateY(180deg) rotatez(10deg)">
                    <div style="transform: rotateY(-180deg)">Mitarbeiter</div>
                </div>
                <div>Raum</div>
                <div class="left" style="transform: rotateY(180deg) rotatez(-05deg)">
                    <div style="transform: rotateY(-180deg)">Kalender / Zeit</div>
                </div>
                <div>Abrechnung</div>
                <!-- <template #sonstiges>
                    <svg viewBox="0 0 100 200">
                        <circle cx="50" cy="50" r="40"/>
                        <polygon points="20,190 30,80 70,80 80,190"/>
                    </svg>
                </template> -->
            </wegweiser>
            <p>
                <center><b>Nicht viele sondern ein Weg. Alle Arbeitsabläufe und Ressourcen gehen in eine Richtung. Das ist der Schlüssel für eine effiziente Ressourcennutzung.</b></center>
            </p>
        </div>
        <div style="height: 5em"></div>
        <div class="box"><br>
            <h2>Kurz und knapp:</h2>
            <h3>In der Patientenverwaltung</h3>
            <p>
                <ul>
                    <li>wird jeder einzelne <b>Behandlungsfortschritt</b> dokumentiert.</li>
                    <li>
                        sind alle bereits abgerechneten<b> Leistungen</b> einzusehen.<br>
                        <ul>
                            <li>Die Verbindungen zwischen <b>Rechnung und Zahlungseingang</b> ist ersichtlich.</li>
                            <li>Die noch nicht berechneten <b>Behandlungen</b> werden separat ausgewiesen.</li>
                        </ul>
                    </li>
                    <li>ist ersichtlich, welcher <b>Therapeut/Mitarbeiter </b>den Kunden betreut hat.</li>
                    <li>ist der belegte <b>Raum/Arbeitsplatz</b> im Bericht.</li>
                    <li>werden vorhandene, zum Patienten gehörige <b>Informationen (z.B. Röntgenbilder, Berichte)</b> gespeichert. </li>
                </ul><br>
            </p>
        </div>
        <div style="height: 1em"></div>
    </div>
</template>

<script>
export default {
    data() {return{
        tab: '',
        wegweiserSichtbar: false,
    }}
}
</script>

<style lang="scss" scoped>
.schlüsselloch {
    // mask: linear-gradient(#fff,#fff);

    // transition: mask .3s;

    &:hover {
        mask: url('../assets/schlüsselloch.svg')
            calc(50% + 17em) 3em
            no-repeat
            exclude,
            linear-gradient(#fff,#fff);
    }
}

.wegweiser {
	margin-bottom: 100px;
	height: 350px;
	// svg {
	// 	position: absolute;
	// 	right: 8em;
	// 	height: 200px;
	// 	fill: transparent;
	// 	transition: fill 0.1s linear 0.6s;
	// }
	// &:hover svg {
	// 	fill: #fff;
	// }

}
</style>